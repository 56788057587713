import React from 'react';
import {BlogSection
} from './blogpage.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import BlogList from "../BlogList";
import BlogSidebar from "../BlogSidebar";

const BlogPage  = () => {
    return (
        <BlogSection>
            <Container>
                <Row>
                    <Col lg={8}>
                        <BlogList />
                    </Col>
                    <Col lg={4}>
                        <BlogSidebar />
                    </Col>
                </Row>
            </Container>
        </BlogSection>
    );
}
export default BlogPage;