import React from "react";
import Banner from "../containers/CommonBanner";
import Headermenu from "../containers/HeaderMenuBS";
import Footer from "../containers/Footer";
import BlogPage from "../containers/BlogPage/BlogPage";
import GlobalStyle from "../components/global-styles";
import "../components/layout.css";
import SEO from "../components/seo";
import BackgroundImg from '../assets/blog-images/banner.jpg';
import Fonts from "../components/fonts";

const BlogListPage = () => (
   <div>
       <Fonts/>
       <GlobalStyle />
       <main>
            <SEO 
               title="Blog | Pool Website Template | GatsbyJS Templates | Garnet"
               description="Clean and professional React GatsbyJS Pool Website template from Garnet"
            />
            <Headermenu />
            <Banner 
                BackgroundImg={BackgroundImg}
                pageTitle="Our Blogs"
                breadCrumbsFirst="Home"
                breadCrumbsSecond="Our Blogs"
                breadCrumbsFirstLink="/"
                breadCrumbsSecondLink="/bloglist"
            />
            <BlogPage />
        </main>
        <Footer />
   </div>
)

export default BlogListPage;
