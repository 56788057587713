import styled from 'styled-components';
import {Commonbtn,Commonpara,Commonh4} from '../../../components/common.style';
import {device} from '../../../components/device';

export const BlogListLayout = styled.div`
    background:#fafafa;
    padding:30px;
    margin-bottom:30px;

    :last-child{
        margin-bottom:0px;
    }

    @media ${device.laptop} {
        :last-child{
            margin-bottom:30px;
        }
    }

    @media ${device.mobileM} {
        padding:15px;
    }
`;
export const BlogListWrapper = styled.div`

`;
export const BlogImageHolder = styled.div`
    margin-bottom:20px;
`;
export const Title = styled(Commonh4)`
    font-size:26px;
    text-align:left;

`;

export const InfoLayout = styled.div`
    display:flex;
`;

export const BlogDate = styled(Commonpara)`
    margin-bottom:10px;
    color:#2560d4;
`; 

export const BlogText = styled(Commonpara)`

`;

export const BottomLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
`;

export const Author = styled(Commonpara)`
    margin-bottom:0px;
    margin-left:10px;
`;


export const ReadMore = styled(Commonbtn)`

`;

