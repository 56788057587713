import React from 'react';
import {BlogListLayout,BlogListWrapper,BlogImageHolder,Title,InfoLayout,BlogDate,
    BlogText,BottomLayout,Author,ReadMore
} from './bloglist.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const BlogListPage  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        blogPageJson{
            BlogList{
                BlogImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                Title
                BlogDate
                BlogText
                Author
                ReadMore
            }
        }
    }
  `);

    return (
        <BlogListWrapper>
        {
            JSONData.blogPageJson.BlogList.map((item,idx) => {
            return <BlogListLayout>
                <BlogImageHolder>
                <GatsImg 
                    fluid={item.BlogImage.childImageSharp.fluid} 
                    alt=""
                />
            </BlogImageHolder>
            <Title>
            {item.Title} 
            </Title>
            <InfoLayout>
                <BlogDate>
                {item.BlogDate} 
                </BlogDate>
                <Author>
                    {item.Author} 
                </Author>
            </InfoLayout>
            <BlogText>
            {item.BlogText} 
            </BlogText>
            <BottomLayout>
                <ReadMore href="/blogdetails">
                {item.ReadMore} 
                </ReadMore>
            </BottomLayout>
            </BlogListLayout>
            })
            }
        </BlogListWrapper>
    );
}
export default BlogListPage;